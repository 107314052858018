import Satisfaction from "views/Satisfaction";

export const satisfactionRoutes = (prefix = "") => {
    return [
        {
            path: `${prefix}`,
            section: Satisfaction,
        },
    ];
};
