export const fetchInvoiceData = (selectedHotelRef, roomNumber, rooms, hotels, setLoading, getInvoice) => {
    if (!selectedHotelRef || !roomNumber || !rooms || !hotels || hotels.length === 0) {
        setLoading(false);
        return;
    }

    const hotel = findHotelByRef(hotels, selectedHotelRef);
    if (!hotel) {
        setLoading(false);
        return;
    }

    const roomInHotel = getRoomInHotel(rooms, roomNumber);
    if (!roomInHotel) {
        setLoading(false);
        return;
    }

    roomInHotel.number = roomNumber;
    getInvoice({
        variables: {
            hotelRef: selectedHotelRef,
            roomNumber: roomNumber,
            password: roomInHotel.code,
        },
    });
};

const findHotelByRef = (hotels, ref) => {
    return hotels.find((h) => h.ref === ref);
};

const getRoomInHotel = (rooms, roomNumber) => {
    return rooms[roomNumber] || null;
};

export const arrangeItems = (items) => {
    const response = [];
    if (items && items.length > 0) {
        items.forEach((item) => {
            let date = new Date(item.date).toISOString().split("T")[0];
            response.push({
                name: item.description,
                price: item.price,
                date: date,
                quantity: item.quantity,
            });
        });
    }
    return response;
};
