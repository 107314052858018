import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useUpdateProfile } from "../graphql/useUser";
import { updateUserData, setUpdateUserFormValues, getUserData } from "../actions/user";
import Loading from "../components/Loading";
import swal from "@sweetalert/with-react";
import isoCountries from "../utils/countries";
import useNetwork from "../hooks/useNetwork";
import CountrySearch from "./CountrySearch";
import history from "../routes/history";

const Edit = () => {
    const { t, i18n } = useTranslation(["interface", "country"]);
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const dispatch = useDispatch();
    const isOnline = useNetwork();
    const user = useSelector(getUserData);
    const lastUpdateUserFormValues = useSelector((state) =>
        state && state.user ? state.user.lastUpdateUserFormValues : null
    );
    const [redirectTo, setRedirectTo] = useState("");
    const [countrySearch, setCountrySearch] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(user ? user.country : null);

    const { register, getValues, handleSubmit, watch, errors, triggerValidation, setValue } = useForm({
        defaultValues: {
            gender: (user ? user.gender : null) || "none",
            name: user ? user.name : null,
            surname: user ? user.surname : null,
            birth: user ? user.birthDate : null,
            country: user ? user.country : null,
            phone: user ? user.phone : null,
        },
    });

    useEffect(() => {
        if (lastUpdateUserFormValues) {
            Object.keys(lastUpdateUserFormValues).forEach(function (key) {
                setValue(key, lastUpdateUserFormValues[key]);
            });
        }
        if (selectedCountry !== "") {
            setValue("country", selectedCountry);
        }
    }, [selectedCountry, lastUpdateUserFormValues, setValue]);

    const connectionError = () => {
        swal({
            title: t("error.connection"),
            text: t("error.editProfile") + "\n" + t("error.editProfileAction"),
            button: t("alert ok"),
        });
    };

    const { updateProfile, loading } = useUpdateProfile({
        onCompleted: (data) => {
            const user = data && data.updateUserProfile ? data.updateUserProfile.user : null;
            if (user) {
                dispatch(updateUserData(user));
                setRedirectTo("/profile");
            } else {
                connectionError();
            }
        },
        onError: (error) => {
            console.error(error);
            connectionError();
        },
    });

    if (!user) {
        return <Redirect to="/signin" />;
    }

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const saveFormState = () => {
        dispatch(setUpdateUserFormValues(getValues()));
    };

    const onSubmit = (data, e) => {
        updateProfile({
            variables: {
                gender: data.gender,
                name: data.name,
                surname: data.surname,
                birthDate: data.birth || "<null>",
                country: data.country,
                phone: data.phone,
                language: i18nLang,
                termsAccepted: data.terms,
                marketingAccepted: data.marketing,
            },
        });
    };

    const checkGender = (value) => {
        return watch("gender") === value ? "btn-neutral-contrast" : "btn-neutral";
    };

    if (!isOnline) {
        connectionError();
    }

    return countrySearch ? (
        <CountrySearch set={setSelectedCountry} show={setCountrySearch} />
    ) : (
        <section className="basic has-tabs has-top">
            <Header title={t("edit")} />
            <section>
                {loading ? (
                    <Loading />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <div className="font-medium text-sm pl-1 pb-3">{t("treatment")}</div>

                            <input
                                name="gender"
                                type="radio"
                                className="hidden"
                                value="female"
                                id="female"
                                ref={register}
                            />
                            <input
                                name="gender"
                                type="radio"
                                className="hidden"
                                value="male"
                                id="male"
                                ref={register}
                            />
                            <input
                                name="gender"
                                type="radio"
                                className="hidden"
                                value="none"
                                id="none"
                                ref={register}
                            />

                            <div className="flex justify-between">
                                <label htmlFor="female" className={"w-full btn btn-thin " + checkGender("female")}>
                                    {t("female")}
                                </label>

                                <label htmlFor="male" className={"w-full mx-3 btn btn-thin " + checkGender("male")}>
                                    {t("male")}
                                </label>

                                <label htmlFor="none" className={"w-full btn btn-thin " + checkGender("none")}>
                                    {t("none")}
                                </label>
                            </div>
                        </div>

                        <div className="my-3">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("name") && "invisible")
                                }
                            >
                                {t("name")}
                            </div>
                            <input
                                ref={register({ required: t("required input") })}
                                type="text"
                                name="name"
                                id="input-name"
                                placeholder={t("name")}
                                onChange={() => triggerValidation("name")}
                                className={errors.name ? "error" : ""}
                            />
                            {errors.name && <div className="text-error float-right text-sm">{errors.name.message}</div>}
                        </div>

                        <div className="my-3">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("surname") && "invisible")
                                }
                            >
                                {t("surname")}
                            </div>
                            <input
                                ref={register({ required: t("required input") })}
                                type="text"
                                name="surname"
                                id="input-surname"
                                placeholder={t("surname")}
                                onChange={() => triggerValidation("surname")}
                                className={errors.surname ? "error" : ""}
                            />
                            {errors.surname && (
                                <div className="text-error float-right text-sm">{errors.surname.message}</div>
                            )}
                        </div>

                        <div className="my-3 flex">
                            <div className="flex-1 mr-1">
                                <div className="float-left font-medium text-sm pl-1 pb-1 ">{t("birth")}</div>
                                <input
                                    ref={register()}
                                    type="date"
                                    name="birth"
                                    id="input-birth"
                                    placeholder="DD/MM/YYYY"
                                    max={new Date().toISOString().split("T")[0]}
                                    onChange={() => triggerValidation("birth")}
                                    className={errors.birth ? "error" : ""}
                                />
                                {errors.birth && (
                                    <div className="text-error float-right text-sm">{errors.birth.message}</div>
                                )}
                            </div>
                            <div className="flex-1 ml-1">
                                <div className={"float-left font-medium text-sm pl-1 pb-1 "}>{t("country")}</div>
                                <select
                                    ref={register()}
                                    type="text"
                                    name="country"
                                    id="input-country"
                                    placeholder={t("country")}
                                    onFocus={() => {
                                        setCountrySearch(true);
                                        setSelectedCountry("notISO");
                                        saveFormState();
                                    }}
                                    onChange={() => triggerValidation("country")}
                                    className={errors.country ? "error" : ""}
                                >
                                    <option value=""></option>
                                    {isoCountries
                                        .sort((a, b) => {
                                            const aname = t("country:" + a);
                                            const bname = t("country:" + b);
                                            return aname.toLowerCase().localeCompare(bname.toLowerCase());
                                        })
                                        .map((iso, index) => (
                                            <option key={index} value={iso}>
                                                {t("country:" + iso)}
                                            </option>
                                        ))}
                                </select>
                                {errors.country && (
                                    <div className="text-error float-right text-sm">{errors.country.message}</div>
                                )}
                            </div>
                        </div>

                        <div className="my-3">
                            <div
                                className={
                                    "float-left font-medium text-sm pl-1 pb-1 " + (!watch("phone") && "invisible")
                                }
                            >
                                {t("phone")}
                            </div>
                            <input
                                ref={register()}
                                type="text"
                                name="phone"
                                id="input-phone"
                                placeholder={t("phone")}
                                onChange={() => triggerValidation("phone")}
                                className={errors.phone ? "error" : ""}
                            />
                            {errors.phone && (
                                <div className="text-error float-right text-sm">{errors.phone.message}</div>
                            )}
                        </div>

                        <div className="my-3">
                            <div className="font-medium text-sm pl-1 pb-1 ">{t("email")}</div>
                            <div className="pl-1">{user ? user.email : ""}</div>
                        </div>

                        <div className="text-right">
                            <button
                                type="button"
                                id="action-cancel"
                                onClick={() => {
                                    history.goBack();
                                }}
                                className="btn w-1/2 mx-auto my-5 text-lg"
                            >
                                {t("cancel")}
                            </button>

                            <button
                                id="action-save"
                                type="submit"
                                className="btn btn-accent w-1/2 mx-auto my-5 text-lg"
                            >
                                {t("save changes")}
                            </button>
                        </div>
                    </form>
                )}
            </section>
            <Tabs />
        </section>
    );
};

export default Edit;
