import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorPage from "../components/ErrorPage";
import { useTranslation } from "react-i18next";
import pkg from "../../package.json";
import swal from "@sweetalert/with-react";
import { queryString } from "../utils/url";
import { getSiteName } from "../actions/config";
import { useSetTokenLanguage } from "../graphql/useUser";
import { useConfiguredTerms, useConfiguredPrivacy } from "../utils/config";
import { getUserData } from "../actions/user";
import { useGetContentLanguages } from "graphql/useGetContentLanguages";
import Loading from "components/Loading";

const Config = () => {
    const { t, i18n } = useTranslation(["interface", "language"]);
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const storageLangContent = localStorage.getItem("storage_lang_content") || i18nLang;
    const config = window.appConfig;
    const user = useSelector(getUserData);
    const { languages, loading } = useGetContentLanguages();
    const t_en = i18n.getFixedT("en");
    const [redirectTo, setRedirectTo] = useState("");
    const showNewDeleteAccount = pkg.features.newDeleteAccount;
    const [language, setLanguage] = useState(null);

    const terms = useConfiguredTerms(storageLangContent);
    const privacy = useConfiguredPrivacy(storageLangContent);

    useEffect(() => {
        if (storageLangContent) {
            setLanguage(storageLangContent);
        }
    }, [storageLangContent]);

    const { setTokenLanguage } = useSetTokenLanguage({
        onCompleted: () => {
            if (language) {
                i18n.changeLanguage(language);
                localStorage.setItem("storage_lang_i18", language);
                localStorage.setItem("storage_lang_content", language);
            }
            swal.close();
        },
    });

    useEffect(() => {
        if (language) {
            setTokenLanguage({
                variables: {
                    language: language,
                },
            });
        }
    }, [language, setTokenLanguage]);

    const showLanguages = () => {
        swal({
            className: "custom",
            button: false,
            content: (
                <div>
                    <div className="alert-title">
                        <button className="alert-close" id="action-back" onClick={() => swal.close()}>
                            <i className="icon icon-back"></i>
                        </button>
                        <span>{t("language", { count: 100 })}</span>
                    </div>
                    <ul className="options px-5 mb-5">
                        {languages
                            .sort((a, b) => {
                                const aname = t_en("language:" + a);
                                const bname = t_en("language:" + b);
                                return aname.toLowerCase().localeCompare(bname.toLowerCase());
                            })
                            .map((iso, index) => (
                                <li key={index} id={`action-language-${iso}`} onClick={() => setLanguage(iso)}>
                                    <span>{t_en("language:" + iso)}</span>
                                    {storageLangContent === iso && (
                                        <i className="icon icon-checkmark text-accent text-xl"></i>
                                    )}
                                </li>
                            ))}
                    </ul>
                </div>
            ),
        });
    };
    const signOut = () => {
        swal({
            title: t("logout"),
            text: t("logout confirm"),
            buttons: {
                cancel: t("alert cancel"),
                confirm: t("logout"),
            },
        }).then((confirm) => {
            if (confirm) {
                setRedirectTo("/signout");
            }
        });
    };

    const deleteAccountMail = () => {
        let uri = "mailto:" + config.deleteAccountContact;
        const siteName = getSiteName();
        const userName = `${user.name} ${user.surname}`;
        uri +=
            "?" +
            queryString({
                subject: t("delete account mail subject", { app: siteName }),
                body: t("delete account mail body", {
                    app: siteName,
                    user: userName,
                })
                    .split("\n")
                    .join("\r\n"),
            });
        console.info(uri);
        window.location.href = uri;
    };

    const deleteAccount = () => {
        swal({
            title: t("delete account"),
            content: (
                <div>
                    <div className="text-left mb-3">{t("delete account text")}</div>
                    <div
                        id="action-delete-confirm"
                        onClick={deleteAccountMail}
                        className="text-center font-medium underline"
                    >
                        {config.deleteAccountContact}
                    </div>
                </div>
            ),
            buttons: {
                cancel: t("alert cancel"),
                confirm: t("send mail"),
            },
        }).then((confirm) => {
            if (confirm) {
                deleteAccountMail();
            }
        });
    };

    if (!terms || !privacy) {
        return <ErrorPage type="config-terms" />;
    }

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <section className="basic has-tabs has-top">
            <Header title={t("config")} />
            {loading ? (
                <Loading />
            ) : (
                <section>
                    <ul>
                        <li className="border-b">
                            <button
                                id="action-edit-language"
                                onClick={showLanguages}
                                className="flex justify-between w-full py-5"
                            >
                                <span>{t("language")}</span>
                                {language ? <span className="font-bold">{t_en("language:" + language)}</span> : null}
                            </button>
                        </li>
                        {user && user.auth && user.auth.password === true ? (
                            <li className="border-b">
                                <Link id="action-edit-pass" to="newpass" className="flex justify-between w-full py-5">
                                    <span>{t("change pass")}</span>
                                    <span>
                                        <i className="icon icon-right"></i>
                                    </span>
                                </Link>
                            </li>
                        ) : (
                            ""
                        )}
                        <li className="border-b">
                            <Link id="action-terms" to="signup/terms" className="flex justify-between w-full py-5">
                                <span>{t("terms & conditions")}</span>
                                <span>
                                    <i className="icon icon-right"></i>
                                </span>
                            </Link>
                        </li>
                        <li className="border-b">
                            <Link id="action-privacy" to="signup/privacy" className="flex justify-between w-full py-5">
                                <span>{t("privacy policy")}</span>
                                <span>
                                    <i className="icon icon-right"></i>
                                </span>
                            </Link>
                        </li>
                        {user !== null ? (
                            <>
                                {showNewDeleteAccount ? (
                                    <li className="border-b">
                                        <Link
                                            id="action-delete"
                                            to="deleteAccount"
                                            className="flex justify-between w-full py-5"
                                        >
                                            <span>{t("delete account")}</span>
                                            <span>
                                                <i className="icon icon-right"></i>
                                            </span>
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="border-b">
                                        <button
                                            type="button"
                                            id="action-delete"
                                            onClick={deleteAccount}
                                            className="flex justify-between w-full py-5"
                                        >
                                            <span>{t("delete account")}</span>
                                        </button>
                                    </li>
                                )}
                                <li className="border-b">
                                    <button
                                        type="button"
                                        id="action-logout"
                                        onClick={signOut}
                                        className="flex justify-between w-full py-5"
                                    >
                                        <span>{t("logout")}</span>
                                    </button>
                                </li>
                            </>
                        ) : (
                            ""
                        )}
                    </ul>
                    {config && config.socialNetworks && config.socialNetworks.length > 0 ? (
                        <div className="my-10 text-center">
                            {t("follow us")}
                            <ul className="mx-auto mt-2 text-2xl">
                                {config.socialNetworks.map((site, index) => {
                                    return (
                                        <li key={index} className="inline-block">
                                            <a
                                                id={`action-social-${site.id}`}
                                                href={site.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="px-4 py-1"
                                            >
                                                <i className={"icon icon-" + getIconSite(site)}></i>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : null}

                    <div className="mb-3 text-neutral-contrast-40 font-mono text-center mt-10 text-xs">
                        v{pkg.version}
                    </div>
                </section>
            )}
            <Tabs />
        </section>
    );
};

export default Config;

const getIconSite = (site) => {
    if (site.id === "web") {
        return "link";
    } else if (site.id === "x") {
        return "X_twitter";
    } else {
        return site.id;
    }
};
