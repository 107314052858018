import React, { useRef, useState, useEffect, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import swal from "@sweetalert/with-react";
import { toast } from "react-toastify";
import { getSelectedHotel, setSelectedRoom } from "../actions/hotel";
import useReaderScan from "../hooks/useReaderScan";
import history from "../routes/history";
import { getMobileUrl } from "../actions/config";
import Loading from "components/Loading";

const isTesting = process.env.NODE_ENV === "test";
const QrReader = !isTesting ? React.lazy(() => import("modern-react-qr-reader")) : null;

const ScanQR = () => {
    const qr = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const video = document.getElementsByTagName("video");
    if (video[0]) {
        video[0].parentElement.style.position = "absolute";
        video[0].parentElement.style.height = "100%";
    }
    const selectedHotel = useSelector(getSelectedHotel);
    const selectedHotelRef = selectedHotel?.ref;

    const [errors, setErrors] = useState(0);
    const [redirectTo, setRedirectTo] = useState("");
    const [selectedCam, setSelectedCam] = useState("environment");

    const fillCanvas = () => {
        const canvas = document.getElementById("frameVideo");
        const ctx = canvas.getContext("2d");
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const squareSize = 250;
        const centerX = (canvas.width - squareSize) / 2;
        const centerY = canvas.height / 4;
        ctx.clearRect(centerX, centerY, squareSize, squareSize);
    };

    const {
        onReaderScan,
        onReaderLoad,
        onReaderError,
        onReaderChooseDevice,
        legacyMode,
        devices,
        stopScan,
        currentDevice,
        setCurrentDevice,
        toggleDevice,
        loading: loadingScan,
    } = useReaderScan({
        getURL: () => {
            return selectedHotel && selectedHotel.mobilePath ? getMobileUrl(selectedHotelRef) : null;
        },
        onSuccess: async (room) => {
            if (room) {
                dispatch(setSelectedRoom(room));
                setRedirectTo("/site");
            } else {
                setTimeout(stopScan, 3000);
            }
        },
        onError: (error) => {
            setErrors(errors + 1);
            switch (error) {
                case "NOT_FOUND_DATA":
                    toast(t("invalid qr code"));
                    setTimeout(stopScan, 3000);
                    break;
                default:
                    if (errors >= 3) {
                        swal({
                            title: t("invalid qr code title"),
                            text: t("invalid qr code info"),
                            buttons: {
                                cancel: t("alert cancel"),
                                confirm: t("rescan qr"),
                            },
                        }).then((confirm) => {
                            if (confirm) {
                                stopScan();
                            } else {
                                history.goBack();
                            }
                            swal.close();
                        });
                    } else {
                        stopScan();
                    }
            }
        },
    });

    if (!selectedHotelRef) {
        return <Redirect push to="/site/list" />;
    }

    const openImageDialog = () => {
        if (qr.current && legacyMode) {
            qr.current.openImageDialog();
        }
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    async function toggleCam() {
        setSelectedCam((val) => (val == "environment" ? "user" : "environment"));
    }

    return (
        <section className="basic pt-0 pb-0">
            {loadingScan ? (
                <Loading></Loading>
            ) : (
                <section className="qr-view relative">
                    {isTesting ? null : (
                        <>
                            <Suspense fallback={<div>Loading...</div>}>
                                <QrReader
                                    ref={qr}
                                    delay={1000}
                                    facingMode={selectedCam}
                                    resolution={1080}
                                    showViewFinder={false}
                                    onLoad={() => {
                                        onReaderLoad();
                                        fillCanvas();
                                    }}
                                    chooseDeviceId={onReaderChooseDevice}
                                    onError={(e) => {
                                        qr.current.clearComponent();
                                        qr.current.initiate();
                                        onReaderError(e);
                                    }}
                                    onScan={onReaderScan}
                                    legacyMode={legacyMode}
                                    maxImageSize={1080}
                                    constraints={{ facingMode: { exact: selectedCam } }}
                                    className="absolute w-full h-full"
                                    style={{
                                        position: "absolute",
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                        left: 0,
                                        top: 0,
                                        bot: 0,
                                        right: 0,
                                        margin: "auto",
                                    }}
                                />
                            </Suspense>
                            <canvas
                                id="frameVideo"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    left: 0,
                                    top: 0,
                                    bot: 0,
                                    right: 0,
                                    margin: "auto",
                                }}
                            ></canvas>
                            {!legacyMode && devices?.length >= 2 && (
                                <div className="p-5 text-center absolute bottom-0 left-0 right-0 m-auto">
                                    <button id="toggle-cam" onClick={toggleCam}>
                                        <i className="icon icon-cam-switch text-4xl cursor-pointer text-white"></i>
                                    </button>
                                </div>
                            )}
                            {legacyMode && (
                                <div className="p-5 text-center absolute bottom-0 left-0 right-0 m-auto">
                                    <button id="open-image-dialog" onClick={openImageDialog}>
                                        <i
                                            className="icon icon-photo-add text-4xl cursor-pointer text-white"
                                            onClick={openImageDialog}
                                        ></i>
                                    </button>
                                </div>
                            )}
                            <button
                                id={"action-back"}
                                className="absolute"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <i className="icon icon-back text-xl p-3 text-white"></i>
                            </button>
                        </>
                    )}
                </section>
            )}
        </section>
    );
};

export default ScanQR;
