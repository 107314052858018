import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "components/Header";
import { ProjectContext } from "contexts/project";
import NoRoom from "components/CheckOut/NoRoom";
import Tabs from "components/Tabs";
import { useSatisfactionQuestion } from "graphql/useUser";
import { useSelector } from "react-redux";
import { getRoom } from "modules/rooms/store";
import { useSendSatisfactionLevel } from "graphql/useUser";
import { getHotel } from "actions/hotel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Loading from "components/Loading";

const Satisfaction = () => {
    const { t } = useTranslation();
    const { room } = useContext(ProjectContext);

    const roomNumber = room?.number;

    return (
        <section className="basic has-top">
            <Header title={t("rate-your-experience")} />
            <section className="full">{!roomNumber ? <NoRoom className="p-5" /> : <FormSatisfaction />}</section>
            <Tabs />
            {roomNumber ? <Tabs /> : null}
        </section>
    );
};

const FormSatisfaction = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [satisfactionLevel, setSatisfactionLevel] = useState(5);
    const { getSatisfactionQuestion, data, loading: loadingData } = useSatisfactionQuestion();
    const { sendSatisfactionLevel, loading: loadingSendData } = useSendSatisfactionLevel({
        onCompleted: () => {
            toast(
                <div className="flex">
                    <i className=" mt-1 mr-2 icon icon-checkmark text-white "></i>
                    <p className=" whitespace-nowrap">{t("answer-submitted-with-success")}</p>
                </div>
            );
            history.push("/site");
        },
        onError: (error) => {
            toast.error(error.message);
            history.push("/site");
        },
    });
    const hotelRef = useSelector(getHotel);
    const { number, guestID } = useSelector(getRoom) || {};

    const icons = [
        { icon: "icon-Face-very-happy", style: { color: "#2A8A61" }, level: 5 },
        { icon: "icon-Face-happy", style: { color: "#36B37E" }, level: 4 },
        { icon: "icon-Face-neutral", style: { color: "#FFDB1F" }, level: 3 },
        { icon: "icon-Face-sad", style: { color: "#FF991F" }, level: 2 },
        { icon: "icon-Face-angry", style: { color: "#DE350B" }, level: 1 },
    ];

    const [satisfactionQuestion, setSatisfactionQuestion] = useState("");

    useEffect(() => {
        if (typeof getSatisfactionQuestion === "function") {
            getSatisfactionQuestion();
        }
    }, []);

    useEffect(() => {
        const satisfactionQuestion = data?.getFeedbackSetting?.satisfactionQuestion || "";
        if (satisfactionQuestion) {
            setSatisfactionQuestion(satisfactionQuestion.replace(/"/g, ""));
        }
    }, [data]);

    const handleSatisfactionClick = (level) => {
        setSatisfactionLevel(level);
    };

    const selectedIcon = icons.find((icon) => icon.level === satisfactionLevel) || icons[0];

    return (
        <>
            {loadingData || loadingSendData ? (
                <Loading />
            ) : (
                <div style={{ height: "calc(100% - 3.5rem)", paddingBottom: "5rem" }} className="h-full">
                    <div style={{ paddingTop: "2rem" }} className=" px-4 pt-6 text-center font-bold text-lg">
                        {satisfactionQuestion}
                    </div>
                    <div
                        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                        className=" w-full flex items-center justify-center"
                    >
                        <i
                            style={{ color: selectedIcon.style.color, fontSize: "10rem" }}
                            className={`icon ${selectedIcon.icon}`}
                        ></i>
                    </div>
                    <div className=" pt-6 text-center font-bold text-lg">{t("select-an-option")}</div>
                    <div className=" mt-2 flex  w-full px-4 justify-center">
                        {icons.map((iconProperties, index) => {
                            return (
                                <div className=" flex flex-col mt-4 w-full items-center">
                                    <button
                                        id={`button-icon-satisfaction-${index}`}
                                        className={` w-full icon ${iconProperties.icon}   text-lg`}
                                        style={{ ...iconProperties.style, fontSize: "3rem" }}
                                        onClick={() => handleSatisfactionClick(iconProperties.level)}
                                    ></button>
                                    <div
                                        className={
                                            satisfactionLevel === iconProperties.level
                                                ? " w-8 bg-accent mt-2 rounded"
                                                : ""
                                        }
                                        style={{ height: "0.2rem" }}
                                    ></div>
                                </div>
                            );
                        })}
                    </div>
                    <div className=" px-4 mt-16">
                        <button
                            onClick={() => {
                                sendSatisfactionLevel({
                                    variables: {
                                        roomNumber: number,
                                        hotelRef: hotelRef,
                                        guestID: guestID,
                                        satisfactionLevel: satisfactionLevel - 1,
                                    },
                                });
                            }}
                            id="satisfaction-submit-button"
                            className=" w-full btn-accent rounded py-2 font-bold "
                        >
                            {t("submit")}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Satisfaction;
