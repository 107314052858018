import Start from "../views/Start";

import { hotelRoutes } from "./hotel";
import { profileRoutes } from "./profile";
import { staysRoutes } from "./stays";
import { notificationsRoutes } from "./notifications";
import { keysRoutes } from "./keys";
import { authRoutes } from "./auth";
import { cuinerRoutes } from "./integrations/cuiner";
import { messagesRoutes } from "./messages";
import { checkOutRoutes } from "./checkOut";
import { shopRoutes } from "./shop";
import { satisfactionRoutes } from "./satisfaction";

export const routes = [
    {
        path: "/",
        exact: true,
        section: Start,
    },
    ...authRoutes(),
    ...hotelRoutes("/site"),
    ...staysRoutes("/stays"),
    ...notificationsRoutes("/notifications"),
    ...profileRoutes("/profile"),
    ...keysRoutes("/keys"),
    ...cuinerRoutes("/pos"),
    ...messagesRoutes("/messages"),
    ...checkOutRoutes("/checkOut"),
    ...shopRoutes("/sales"),
    ...satisfactionRoutes("/satisfaction"),
    {
        path: "*",
        redirect: "/",
    },
];
