import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import fetchTimeout from "fetch-timeout";

import { getMobileUrl, castSimulator, getIsEmbedded } from "../actions/config";
import { createRoom, updateRoom, deleteRoom } from "../modules/rooms/store";
import { validateRoomCode } from "../utils/others";
import { useSignInRoom } from "../graphql/useHotels";
import { fetchTimeout as fetchTest } from "../utils/mockup";
import { postMessageToParent } from "../components/Design/DesignUtils";
import { NOTIF_ROOM_ADDED, NOTIF_JOIN_WIFI, NotificationContext } from "../contexts/notifications";

export default function useAddRoom({ project }) {
    const { getMessage, displayInfo, displayError } = useContext(NotificationContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { signInRoom } = useSignInRoom();
    const isEmbedded = useSelector(getIsEmbedded);
    const cloudGuests = !project || project?.permissionsConfig?.manageGuests;

    const [loading, setLoading] = useState(false);

    const notify = ({ added, number, pairingEnabled, hasChromecast, wifiConnected }) => {
        const outsideHotelWiFi = pairingEnabled && !wifiConnected;

        const msg = [];
        let notifID = "";
        if (added) {
            msg.push(getMessage(NOTIF_ROOM_ADDED, { number }));
            notifID += NOTIF_ROOM_ADDED;
        } else if (outsideHotelWiFi) {
            msg.push(getMessage(NOTIF_JOIN_WIFI, { name: project?.name }));
            notifID += NOTIF_JOIN_WIFI;
        }
        if (msg.length > 0) {
            // Take a break from last notification (2 minutes)
            displayInfo(msg.join("\n"), { id: notifID, breakTime: 120000 });
        }

        if (pairingEnabled) {
            dispatch(
                updateRoom({
                    projectRef: project.ref,
                    number: number,
                    data: { showPairingMessage: hasChromecast },
                })
            );
        }
    };

    const checkOldRoom = (room) => {
        return new Promise((resolve, reject) => {
            const url = project && project.mobilePath ? getMobileUrl(project.ref) : null;
            validateRoomCode(url, room.number, room.code)
                .then((success) => {
                    if (success) {
                        resolve({
                            projectRef: project.ref,
                            number: room.number,
                            code: room.code,
                        });
                    } else {
                        resolve(false);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const checkCloudRoom = (room) => {
        return new Promise((resolve, reject) => {
            if (!project?.ref || !room?.number || !room?.code) {
                console.error("Invalid parameters: hotelRef, roomNumber, or password are missing.");
                resolve(false);
                return;
            }
            signInRoom({
                variables: {
                    hotelRef: project.ref,
                    roomNumber: room.number,
                    password: room.code,
                },
            })
                .then((r) => {
                    if (r?.data?.signInRoom) {
                        if (r.data.signInRoom.guestID) {
                            resolve(r.data.signInRoom);
                        } else {
                            console.error("Guest ID not found", r.data.signInRoom);
                            resolve(false);
                        }
                    } else if (r && r.errors) {
                        console.error(r.errors);
                        resolve(false);
                    } else {
                        reject(r);
                    }
                })
                .catch((err) => {
                    console.error("Error executing signInRoom:", err);
                    reject(err);
                });
        });
    };

    const checkWiFi = (url, token) => {
        const simulator = castSimulator();
        const pairingURL = url && simulator ? "/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" : url;
        const fetch = simulator ? fetchTest : fetchTimeout;
        const response = {
            pairingEnabled: pairingURL && token ? true : false,
            wifiConnected: false,
            hasChromecast: false,
        };
        return new Promise((resolve) => {
            if (response.pairingEnabled) {
                fetch(pairingURL, { headers: { Accept: "application/json" } }, 6000)
                    .then((http) => {
                        if (!http.ok || http.status == 204) {
                            throw new Error("HTTP " + http.status + " - " + http.statusText);
                        }
                        return http.json();
                    })
                    .then((data) => {
                        if (data) {
                            response.wifiConnected = true;
                            if (data.ccInfo && data.ccInfo.length > 0) {
                                response.hasChromecast = true;
                            }
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        console.error(e);
                        resolve(response);
                    });
            } else {
                resolve(response);
            }
        });
    };

    const addRooms = (rooms, options) => {
        if (!rooms || rooms.length == 0) {
            return;
        }

        const checkRoomEnabled = options?.check;
        const checkWiFiEnabled = options?.checkWiFi;

        return new Promise((resolve, reject) => {
            setLoading(true);

            const checkFunc = cloudGuests ? checkCloudRoom : checkOldRoom;
            const opFunc = checkRoomEnabled ? checkFunc : (room) => Promise.resolve(room);
            const promises = [];
            let anyRoomAdded = false;

            rooms.forEach((room) => {
                promises.push(
                    new Promise((next) => {
                        if (room) {
                            opFunc(room)
                                .then(async (data) => {
                                    if (data) {
                                        const newRoom = {
                                            projectRef: project.ref,
                                            ...room,
                                            ...data,
                                        };
                                        if (newRoom.pairingURL && newRoom.token) {
                                            if (checkWiFiEnabled) {
                                                await checkWiFi(newRoom.pairingURL, newRoom.token).then((data) => {
                                                    Object.assign(newRoom, data);
                                                });
                                            }
                                        }
                                        anyRoomAdded = true;
                                        dispatch(createRoom(newRoom));
                                        if (isEmbedded) {
                                            postMessageToParent({
                                                action: "addRoom",
                                                params: {
                                                    hotelRef: project.ref,
                                                    room: room.number,
                                                    code: room.code,
                                                },
                                            });
                                        } else {
                                            notify({ ...newRoom, added: true });
                                        }
                                    } else {
                                        displayError(
                                            `${t("room x", { room: room.number })}\n${t("invalid room code")}`
                                        );
                                    }
                                })
                                .catch((err) => {
                                    console.error(err);
                                    displayError(`${t("room x", { room: room.number })}\n${t("error.unknown")}`);
                                })
                                .finally(() => {
                                    next();
                                });
                        } else {
                            next();
                        }
                    })
                );
            });

            Promise.all(promises)
                .then(() => {
                    if (resolve) {
                        resolve(anyRoomAdded);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    if (reject) {
                        reject(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const checkRoomStatus = (room) => {
        const checkFunc = cloudGuests ? checkCloudRoom : checkOldRoom;
        checkFunc(room)
            .then((response) => {
                if (response) {
                    checkWiFi(response.pairingURL, response.token)
                        .then((data) => {
                            dispatch(
                                updateRoom({
                                    projectRef: project.ref,
                                    number: room.number,
                                    data: { ...data, guestID: response?.guestID },
                                })
                            );
                            notify({ number: room.number, ...data, guestID: response?.guestID });
                        })
                        .catch((e) => {
                            console.error(e);
                            displayError(`${t("room x", { room: room.number })}\n${t("error.unknown")}`);
                        });
                } else {
                    dispatch(
                        deleteRoom({
                            projectRef: project.ref,
                            number: room.number,
                        })
                    );
                    if (isEmbedded) {
                        postMessageToParent({
                            action: "deleteRoom",
                            params: {
                                hotelRef: project.ref,
                                room: room.number,
                                code: room.code,
                            },
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
                displayError(`${t("room x", { room: room.number })}\n${t("error.unknown")}`);
            });
    };

    return { addRooms, checkRoomStatus, loading };
}
