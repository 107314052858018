import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSelectedHotel } from "actions/hotel";
import TextLabel from "components/TextLabel";
import TextDivider from "components/TextDivider";
import BoxIconValue from "components/BoxIconValue";
import TextLabelGrid from "components/TextLabelGrid";
import TextArea from "components/inputs/TextArea";
import { GUESTS_ROLES, PRECHECKIN_STATUS } from "./utils";
import { convertDateBasedOnLocale } from "utils/dayjs";
import { useConfiguredPrivacy, useConfiguredTerms } from "utils/config";
import swal from "@sweetalert/with-react";
import { handleShowPrivacy, handleShowTerms } from "components/DialogHandlers";

const InformationStayCard = ({ inInformationStay, dataStayBooking, termsAccepted, setTermsAccepted }) => {
    const project = useSelector(getSelectedHotel);
    const history = useHistory();
    const { code } = useParams();
    const { t, i18n } = useTranslation();

    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const storageLangContent = localStorage.getItem("storage_lang_content") || i18nLang;
    const accessConditions = useConfiguredTerms(storageLangContent);
    const privacyPolicy = useConfiguredPrivacy(storageLangContent);

    const { stayHolderEmail, stayHolderName, stayHolderSurname } = dataStayBooking || {};

    const namePlusSurnameMainGuest = stayHolderName + " " + stayHolderSurname;
    const mailMainGuest = stayHolderEmail;

    const numberRooms = GetNumberRooms({
        guests: dataStayBooking?.guests,
    });
    const numberOfRooms = dataStayBooking?.numberOfRooms || 0;

    const adjustedNumberRooms = numberRooms.concat(Array(Math.max(0, numberOfRooms - numberRooms.length)).fill("-"));

    const logs = dataStayBooking?.logs ? dataStayBooking?.logs : [];

    const latestCorrectionRequest = findLatestCorrectionRequest(logs)?.message || null;

    const handleActionClick = () => {
        history.push(`/stays/details/${code}/precheckin`);
    };

    return (
        <>
            <div className="mb-6 flex justify-between items-center">
                <TextLabel label="booking-code" value={dataStayBooking?.code} />
                <div className="flex space-x-2">
                    <BoxIconValue icon=" icon-user-solid text-accent" value={dataStayBooking?.numberOfGuests} />
                    <BoxIconValue icon=" icon-stay-solid text-accent" value={dataStayBooking?.numberOfRooms} />
                </div>
            </div>
            <div className=" mb-4">
                <TextDivider text={project?.name} translatable={false} />
            </div>
            <TextLabelGrid
                grid={[
                    [
                        { label: "arrival", value: convertDateBasedOnLocale(dataStayBooking?.arrivalDate) },
                        { label: "booking-holder", value: namePlusSurnameMainGuest },
                        { label: "email", value: mailMainGuest },
                    ],
                    [{ label: "departure", value: convertDateBasedOnLocale(dataStayBooking?.departureDate) }],
                ]}
            />
            {dataStayBooking?.state === PRECHECKIN_STATUS.PENDING_MODIFICATION.value && (
                <div className=" mt-4">
                    <TextArea
                        disabled={true}
                        name="hotel-comments"
                        icon={"icon-alert text-orange-100"}
                        value={latestCorrectionRequest}
                    />
                </div>
            )}
            {dataStayBooking?.state === PRECHECKIN_STATUS.COMPLETED.value ? (
                <div className=" mt-4">
                    <TextLabel
                        label="rooms"
                        value={adjustedNumberRooms?.length > 0 ? adjustedNumberRooms.join(", ") : null}
                    />
                </div>
            ) : null}
            {inInformationStay && (
                <div className="flex justify-center items-center">
                    <StayActions state={dataStayBooking?.state} onActionClick={handleActionClick} />
                </div>
            )}
            {!inInformationStay && !dataStayBooking?.termsAccepted ? (
                <div className=" mt-4 border-t pt-4">
                    <div>{t("in-order-to-continue-you-need-to-accept-the-terms-and-conditions")}</div>
                    <div className=" mt-4 flex text-sm">
                        <input
                            id="checkbox-terms"
                            type="checkbox"
                            name="terms"
                            className=" mt-1"
                            checked={termsAccepted}
                            onChange={() => {
                                if (typeof setTermsAccepted === "function") {
                                    setTermsAccepted((val) => !val);
                                }
                            }}
                        />
                        <div className=" ml-2 text-base">
                            <label htmlFor="checkbox-terms" className="">
                                {t("accept terms", { terms: " " })}
                            </label>
                            <span
                                onClick={() => {
                                    handleShowTerms(accessConditions, swal);
                                }}
                                href="#"
                                className="text-accent underline cursor-pointer"
                            >
                                {t("terms & conditions")}
                            </span>
                            {t("terms & policy", { terms: " ", privacy: " " })}
                            <span
                                onClick={() => {
                                    handleShowPrivacy(privacyPolicy, swal);
                                }}
                                href="#"
                                className="text-accent underline cursor-pointer"
                            >
                                {t("privacy policy")}
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}

            {!inInformationStay && dataStayBooking?.termsAccepted ? (
                <div className=" mt-4 border-t pt-4">
                    <div className=" mt-4 flex text-sm">
                        <div className=" ml-2 text-base">
                            <span
                                onClick={() => {
                                    handleShowTerms(accessConditions, swal);
                                }}
                                href="#"
                                className="text-accent underline cursor-pointer"
                            >
                                {t("terms & conditions")}
                            </span>
                            {t("terms & policy", { terms: " ", privacy: " " })}
                            <span
                                onClick={() => {
                                    handleShowPrivacy(privacyPolicy, swal);
                                }}
                                href="#"
                                className="text-accent underline cursor-pointer"
                            >
                                {t("privacy policy")}
                            </span>
                            <span className=" ml-2">{t("have-been-acepted")}.</span>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

// function MainGuestInformation({ guests }) {
//     const mainGuest = guests?.find((guest) => guest?.guestRole === GUESTS_ROLES.MAIN);
//     const namePlusSurnameMainGuest = mainGuest ? `${mainGuest.name || ""} ${mainGuest.surname || ""}` : "";
//     const mailMainGuest = mainGuest ? mainGuest.email || "" : "";

//     return { namePlusSurnameMainGuest, mailMainGuest };
// }

function GetNumberRooms({ guests }) {
    const roomNumbers = new Set();

    guests?.forEach((guest) => {
        guest.stayGuestRooms.forEach((room) => {
            roomNumbers.add(room.room.number);
        });
    });
    const uniqueRoomNumbers = Array.from(roomNumbers);

    return uniqueRoomNumbers;
}

function StayActions({ state, onActionClick }) {
    const { t } = useTranslation();

    if (state === PRECHECKIN_STATUS.VALIDATED.value || state === PRECHECKIN_STATUS.PENDING_VALIDATION.value) {
        return <ActionButton onClick={onActionClick} text={t("edit-the-precheckin")} />;
    }
    if (state === PRECHECKIN_STATUS.PENDING_MODIFICATION.value) {
        return <ActionButton onClick={onActionClick} text={t("modify-the-precheckin")} />;
    }
    if (state === PRECHECKIN_STATUS.PENDING_COMPLETION.value) {
        return <ActionButton onClick={onActionClick} text={t("complete-the-pre-check-in")} />;
    }
    return null;
}

function ActionButton({ onClick, text }) {
    return (
        <button
            id="action-button-information-stay-card"
            onClick={onClick}
            className="rounded w-full font-bold text-xl py-2 mt-14 bg-accent text-accent-contrast"
        >
            {text}
        </button>
    );
}

function findLatestCorrectionRequest(logs) {
    const correctionRequests = logs.filter((log) => log.subject === "CORRECTION_REQUEST");
    correctionRequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return correctionRequests.length > 0 ? correctionRequests[0] : null;
}

export default InformationStayCard;
