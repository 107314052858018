import React, { useState, useEffect } from "react";
export const Dropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        setSelectedOption(option);
        props?.onChange(option);
        setIsOpen(false);
    };

    useEffect(() => {
        if (props?.selected) {
            if (typeof props.selected != "object") {
                setSelectedOption(props?.options?.find((val) => val.value == props.selected));
            } else {
                setSelectedOption(props.selected);
            }
        }
    }, []);

    return (
        <div className=" relative">
            <button
                onClick={toggleDropdown}
                className=" bg-white text-black px-3 cursor-pointer border border-gray-300 w-full text-left relative flex justify-between items-center"
            >
                <span>{selectedOption ? selectedOption?.name : "Select an option"}</span>
                <i className=" text-xl text-black icon-down"></i>
            </button>
            {isOpen && (
                <ul className=" absolute bg-white border border-gray-300 list-none w-full p-0 m-0">
                    {props?.options.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className=" p-2 cursor-pointer hover:bg-gray-400"
                        >
                            <span>{option.name}</span>
                            {option?.subtext && <p>{option?.subtext}</p>}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
