import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "graphql/utils";
import { useMutation } from "@apollo/client";
import { GET_KEYS, USE_KEY_TESA } from "graphql/queries/keys";
import ASSA from "../assets/images/ASSA.svg";
import Loading from "components/Loading";
import { getHotels } from "actions/hotel";
import swal from "@sweetalert/with-react";
import { useRooms } from "graphql/useUser";

const formatDate = (date) => {
    const formatDate = new Date(date);
    const day = formatDate.getDate();
    const month = formatDate.getMonth() + 1;
    const year = formatDate.getFullYear();
    const hour = formatDate.getHours();
    const minutes = formatDate.getMinutes();

    return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year} - ${hour
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

const Modal = ({ rooms = [], zones = [], styles, classes, currentKey, t }) => {
    return (
        <div className="flex items-start flex-col">
            <div className="flex items-start gap-2 mb-3">
                {rooms &&
                    rooms.map((room, index) => {
                        return (
                            <span style={styles} className={classes} key={`modal-room-${index}`}>
                                R.{room}
                            </span>
                        );
                    })}
                {zones &&
                    zones.map((zone, index) => {
                        return (
                            <span style={styles} className={classes} key={`modal-zone-${index}`}>
                                {zone}
                            </span>
                        );
                    })}
            </div>
            <div className="w-full text-left">
                <span>{t("valid-until")}:</span>
                <span className=" ml-2 font-semibold">{formatDate(currentKey.expireTime)}</span>
            </div>
        </div>
    );
};

const KeysMap = ({ rooms = [], zones = [], currentKey, index, t, guestRooms }) => {
    const classes = "rounded-md border-gray-600 font-light px-4 py-1 h-8";
    let limitSpace = 0;
    let exceptions = 0;
    const styles = {
        border: "1px solid gray",
    };
    return (
        <div className="flex flex-wrap gap-2 mb-5 w-full items-center" key={`keymap-inside-${index}`}>
            {rooms &&
                rooms.length > 0 &&
                rooms.map((room) => {
                    if (limitSpace <= 32) {
                        limitSpace += 2 + room.toString().length;
                        if (limitSpace > 32) {
                            exceptions++;
                        }
                    } else {
                        exceptions++;
                    }
                    const roomInformation = guestRooms?.find((val) => val.number == room);
                    return (
                        <>
                            {limitSpace <= 32 && (
                                <div style={styles} className={classes} key={`room-list-${room}`}>
                                    {roomInformation?.name}
                                </div>
                            )}
                        </>
                    );
                })}
            {zones &&
                zones.length > 0 &&
                zones.map((zone) => {
                    if (limitSpace <= 33) {
                        limitSpace += zone.toString().length;
                        if (limitSpace > 33) {
                            exceptions++;
                        }
                    } else {
                        exceptions++;
                    }
                    return (
                        <>
                            {limitSpace <= 33 && (
                                <div style={styles} className={classes} key={`zone-list-${zone}`}>
                                    {zone}
                                </div>
                            )}
                        </>
                    );
                })}
            {exceptions > 0 && (
                <span
                    onClick={() => {
                        swal({
                            content: (
                                <Modal
                                    rooms={rooms}
                                    zones={zones}
                                    t={t}
                                    currentKey={currentKey}
                                    styles={styles}
                                    classes={classes}
                                />
                            ),
                            buttons: {
                                cancel: t("accept"),
                            },
                        });
                    }}
                    className=" text-blue-800 px-4 py-1 cursor-pointer"
                >
                    +{exceptions}
                </span>
            )}
        </div>
    );
};

const Keys = () => {
    const { t } = useTranslation(["interface", "language"]);
    const [connectig, setConnectig] = useState(false);
    const [keys, setKeys] = useState([]);
    const [error, setError] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const [message, setMessage] = useState();
    const [difference, setDifference] = useState();
    const [guestRooms, setGuestRooms] = useState();
    const rooms = useRooms({
        onCompleted: (data) => {
            setGuestRooms(data.getRooms.results);
        },
    });
    const hotels = useSelector(getHotels);
    const keysOptions = ["ASSA_ABLOY", "SALTO", "TESA"];
    const messages = {
        connecting: { message: "connecting-message" },
        noAccess: { message: "no-access" },
        doorOpen: { message: "door-open", values: { door: keys.length > 0 && keys[currentIndex]?.rooms[0] } },
        openDoor: { message: "touch-the-key-button-to-open-the-door" },
        openDoorWithPhone: { message: "touch-the-key-button-and-bring-the-phone-closer" },
    };

    const buttonsStyles = {
        open: {
            button: "bg-green-500 rounded-full h-20 w-20",
            icon: "icon icon-Lock text-white text-4xl",
        },
        lockFail: {
            button: "bg-orange-500 rounded-full h-20 w-20",
            icon: "icon icon-Lock text-white text-4xl",
        },
        connect: {
            button: "h-16 w-16 cursor-auto",
            icon: "icon icon-Connect text-blue-800 text-4xl",
        },
        key: {
            button: "bg-accent rounded-full h-20 w-20",
            icon: "icon icon-key text-accent-contrast  text-4xl",
            function: () => {
                setConnectig(true);
            },
        },
        keyDisable: {
            button: "bg-gray-400 rounded-full h-20 w-20",
            icon: "icon icon-key text-white text-4xl",
        },
        fail: {
            button: "bg-red-600 rounded-full h-20 w-20 cursor-default",
            icon: "icon icon-close text-4xl text-white",
        },
    };
    const {
        query,
        loading,
        data,
        error: queryError,
    } = useQuery(GET_KEYS, {
        maxAge: 600000, // 10 minutes
        fetchPolicy: "network-only",
    });

    const [mutationUseKey, { loading: loadingMutation, error: errorMutation }] = useMutation(USE_KEY_TESA.query, {
        onCompleted: (data) => {
            if (data.useKey) {
                setMessage(messages.doorOpen);
                setCurrentButton(buttonsStyles.open);
                setTimeout(() => {
                    setConnectig(false);
                    setMessage(messages.openDoor);
                    setCurrentButton(buttonsStyles.key);
                }, 5000);
            }
        },
        onError: () => {
            setError(true);
        },
    });

    const [currentButton, setCurrentButton] = useState(buttonsStyles.keyDisable);
    const [project, setProject] = useState();

    useEffect(() => {
        if (loadingMutation) {
            setConnectig(true);
        }
    }, [loadingMutation]);

    useEffect(() => {
        if (errorMutation) {
            setError(true);
        }
        if (queryError) {
            setCurrentButton(buttonsStyles.keyDisable);
            setMessage(messages.noAccess);
        }
    }, [queryError, errorMutation]);

    useEffect(() => {
        if (connectig) {
            setCurrentButton(buttonsStyles.connect);
            setMessage(messages.connecting);
            mutationUseKey({
                variables: { hotelRef: keys[currentIndex].hotelRef, roomNumber: keys[currentIndex].rooms[0] },
            });
        }
    }, [connectig]);

    useEffect(() => {
        if (error) {
            setCurrentButton(buttonsStyles.fail);
            setTimeout(() => {
                setError(false);
            }, 2000);
        } else {
            if (keys.length > 0) {
                setMessage(messages.openDoor);
                setCurrentButton(buttonsStyles.key);
            } else {
                setMessage(messages.noAccess);
                setCurrentButton(buttonsStyles.keyDisable);
            }
            setConnectig(false);
        }
    }, [error]);

    useEffect(() => {
        if (keys.length > 0) {
            if (keys[currentIndex].keyBrand === keysOptions[2]) {
                setMessage(messages.openDoor);
            } else {
                setMessage(messages.openDoorWithPhone);
            }
            setCurrentButton(buttonsStyles.key);
        } else {
            setCurrentButton(buttonsStyles.keyDisable);
        }
    }, [keys]);

    useEffect(() => {
        if (keys.length === 0) {
            query();
            rooms.update();
        }
    }, []);

    useEffect(() => {
        if (data?.getKeys?.results?.length > 0) {
            setKeys(data.getKeys.results);
            setProject(hotels.find((hotel) => hotel.ref === data.getKeys.results[0].hotelRef));
            setCurrentIndex(0);
        } else {
            setKeys([]);
            setMessage(messages.noAccess);
            setCurrentButton(buttonsStyles.keyDisable);
        }
    }, [data?.getKeys]);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            if (difference > 10) {
                setCurrentIndex((prevIndex) => (prevIndex === keys.length - 1 ? 0 : prevIndex + 1));
            } else if (difference < -10) {
                setCurrentIndex((prevIndex) => (prevIndex === 0 ? keys.length - 1 : prevIndex - 1));
            }
        }, 50);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [difference]);

    const handleTouchStart = (e) => {
        if (e.touches) {
            setStartX(e.touches[0].clientX);
        } else {
            setStartX(e.clientX);
        }
    };

    const handleTouchMove = (e) => {
        const currentX = e.touches ? e.touches[0].clientX : e.clientX;
        const difference = startX - currentX;
        setDifference(difference);
    };

    return (
        <section className="basic has-top">
            <Header title={t("keys")} />
            {loading ? (
                <Loading></Loading>
            ) : (
                <section className="w-full h-full flex flex-col items-center">
                    <div
                        className=" border-b-2 border-blue-800 w-full flex justify-between cursor-pointer"
                        id="title-container"
                    >
                        <span id="my-keys-title">{t("my-keys")}</span>
                        <button
                            id="reload-button"
                            onClick={() => {
                                query();
                            }}
                        >
                            <i className="icon icon-refresh text-blue-800 "></i>
                        </button>
                    </div>
                    {keys.length > 0 ? (
                        <div
                            onTouchMove={handleTouchMove}
                            onTouchStart={handleTouchStart}
                            onMouseUp={handleTouchMove}
                            onMouseDown={handleTouchStart}
                            className={`flex overflow-hidden py-4 w-full flex-row ${
                                keys.length === 1 && "justify-center align-middle"
                            }`}
                        >
                            {keys.map((key, index) => {
                                const roomInformation = guestRooms?.find((val) => val.number == key.rooms[0]);
                                return (
                                    <div
                                        style={{
                                            flex: `0 0 90%`,
                                            transition: "transform 1s",
                                            transform: `translateX(${currentIndex > 0 ? "-" : ""}${
                                                (currentIndex === 0 && index === currentIndex
                                                    ? 5
                                                    : currentIndex === 0 && 7) +
                                                (index <= currentIndex - 2 && currentIndex * 100) +
                                                (currentIndex > 0 &&
                                                    index === currentIndex - 1 &&
                                                    97 + (currentIndex > 1 && (currentIndex - 1) * 100)) +
                                                (currentIndex > 0 &&
                                                    index === currentIndex &&
                                                    94.5 + (currentIndex > 1 && (currentIndex - 1) * 100)) +
                                                (currentIndex > 0 &&
                                                    index === currentIndex + 1 &&
                                                    92 + (currentIndex > 1 && (currentIndex - 1) * 100))
                                            }% )`,
                                        }}
                                        key={`key-container-${index}`}
                                    >
                                        <div
                                            style={{ minHeight: "160px" }}
                                            className={`relative rounded-md py-2 px-4 bg-white flex justify-center items-center flex-col`}
                                        >
                                            <p className=" text-center font-bold">{project?.name}</p>
                                            <KeysMap
                                                rooms={key.rooms}
                                                zones={key.zones}
                                                guestRooms={guestRooms}
                                                index={index}
                                                currentKey={key}
                                                key={`keyMap-${index}`}
                                                t={t}
                                            ></KeysMap>
                                            {roomInformation?.stayTo ? (
                                                <div className="w-full absolute bottom-0 pl-4 pb-1">
                                                    <span>{t("valid-until")}:</span>
                                                    <span className=" ml-2 font-semibold">
                                                        {formatDate(key.expireTime)}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                        {currentIndex === index && (
                                            <p className="mt-2 border-t-2 border-blue-800 text-center w-full">
                                                {keys[currentIndex].keyBrand === keysOptions[2]
                                                    ? t("key-selected")
                                                    : t("x selected", { count: project?.name })}
                                            </p>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>{t("try-to-refresh-your-access")}...</p>
                    )}

                    {error && (
                        <>
                            <p>{t("error-open-door")}</p>
                            <p>{t("try-again")}</p>
                        </>
                    )}
                    <div
                        className={`${
                            keys.length > 0 ? " mt-10" : "absolute bottom-0 mb-10"
                        } w-full flex flex-col items-center `}
                    >
                        {!error && (
                            <p className="text-center mb-8">
                                {message?.values ? t(message?.message, message?.values) : t(message?.message)}
                            </p>
                        )}
                        {currentButton.function ? (
                            <button
                                className={` mb-16 ${currentButton.button}`}
                                onClick={currentButton.function}
                                id="button"
                            >
                                <i className={currentButton.icon}></i>
                            </button>
                        ) : (
                            <span
                                className={`flex justify-center items-center mb-16 ${currentButton.button}`}
                                id="current-action"
                            >
                                <i className={currentButton.icon}></i>
                            </span>
                        )}
                        {keys.length > 0 && keys[currentIndex].keyBrand === keysOptions[0] && (
                            <img src={ASSA} width={80} height={25} className=" mb-10" />
                        )}
                    </div>
                </section>
            )}
        </section>
    );
};

export default Keys;
