import React from "react";
import { size as renderSize } from "zrender/sizes";

import { getDeviceWidth } from "./Design/DesignUtils";

const ButtonsMenu = ({ keyID, id, style, items }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <ul
            key={keyID}
            id={id}
            className="whitespace-nowrap buttons-menu"
            style={{
                overflow: "overlay",
                padding: size(0.7),
                fontSize: size(1.4),
                ...style,
            }}
        >
            {items?.map((item) => (
                <MenuItem {...item}>{item?.name}</MenuItem>
            ))}
        </ul>
    );
};

const MenuItem = ({ style, children, selected, key, onClick }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <li
            key={key}
            onClick={onClick}
            className={"cursor-pointer inline-block btn font-normal " + (selected ? "btn-accent" : "btn-primary")}
            style={{
                margin: size(0.7) + " " + size(0.35),
                padding: size(0.28) + " " + size(0.7),
                ...style,
            }}
        >
            {children}
        </li>
    );
};

export default ButtonsMenu;
